var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-spin", { attrs: { spinning: _vm.fetching } }, [
    _c(
      "section",
      [
        _c("page-header", {
          staticClass: "mb-xs",
          attrs: { "show-back-icon": "", title: _vm.currentPage.name },
          on: {
            back: function() {
              return _vm.$router.go(-1)
            }
          }
        }),
        _c("search-box", {
          staticClass: "pb-sm",
          attrs: {
            display: _vm.queryParam.mode,
            queryParam: _vm.queryParam,
            category: _vm.category
          },
          on: {
            toggleView: _vm.onChangeView,
            handleSearch: _vm.handleSearch,
            changeTitle: _vm.changeTitle,
            changeStatus: _vm.changeStatus,
            handleReset: _vm.handleReset,
            changeCategory: _vm.changeCategory
          }
        }),
        _c(
          "a-spin",
          { attrs: { spinning: _vm.loading } },
          [
            _vm.queryParam.mode === "list"
              ? _c(
                  "div",
                  { staticClass: "spin-content" },
                  [
                    _c("SGrid", {
                      ref: "sGrid",
                      attrs: {
                        rowData: _vm.codes,
                        enableCheckbox: false,
                        columnDefs: _vm.columnDefs,
                        gridContext: _vm.context,
                        pagination: _vm.pagination,
                        "grid-name": "code-archive",
                        rowHeight: 136
                      },
                      on: { onPageChange: _vm.handleChangePage }
                    })
                  ],
                  1
                )
              : _c("grid-list", {
                  attrs: {
                    rowData: _vm.codes,
                    pagination: _vm.pagination,
                    context: _vm.context
                  },
                  on: { onPageChange: _vm.handleChangePage }
                })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }